<template>
  <div class="integralJL">
    <ul class="box-s">
      <li v-for="item in data" @click="go(item.CIHCOrderId)">
        <p>日期：{{item.CIHTimeString}}</p>
        <p>积分：{{item.CIHValue}}</p>
        <el-button>相关订单</el-button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "integralJL",
  data() {
    return {
      data: []
    }
  },
  created() {
    if (this.$store.state.users.CsUser) {
      this._api.userCurdApi.getIntegral()
          .then(res => {
            if (res.GetListResult && res.GetListResult.length) {
              this.data = [...res.GetListResult]
            }
          })
    }else {
      this.$alert('顾客未登录，请登录')
    }
  },
  methods: {
    go(id) {
      this.$router.push({path: `/Iframe?type=1&oid=${id}`})
    }
  }
}
</script>

<style scoped lang="scss">
  .box-s {
    padding: 0 5px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      p:nth-child(1) {
        //width: ;
        width: 200px;
        text-align: left;
      }
      p:nth-child(2) {
        //width: ;
        width: 120px;
        text-align: left;
      }
      border-bottom: 1px solid #7f7e7e;
      padding: 10px 0;
    }
  }
</style>
